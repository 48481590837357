import { createLocalVue } from '@vue/test-utils';

import AdyenCheckout from '@adyen-web-5.59.0';

import Vuex from 'vuex';
import {
  createFromAction, handleOnAdditionalDetails, onAdditionalDetails, storeRedirectPaymentMethod,
} from './utils';

import {
  authenticateChallenge,
  authenticateRedirect,
  storePaymentMethodByUUID,
} from '../api/adyenApi';

jest.mock('@adyen-web-5.59.0/dist/adyen.css', () => { });

jest.mock('../api/adyenApi.js', () => ({
  authenticateChallenge: jest.fn().mockResolvedValue({}),
  authenticateRedirect: jest.fn().mockResolvedValue({}),
  storePaymentMethodByUUID: jest.fn().mockResolvedValue({
    md: 'testMD',
    paRes: 'testPaResponse',
  }),
}));

const CHALLENGE_ENDPOINT_VERSION = {
  add: 'v3',
};

const localVue = createLocalVue();
localVue.use(Vuex);

const profileDataStore = {
  state: {
    currentProfile: {
      type: jest.fn(),
    },
  },
};

// to be fixed upon finishing porting new endpoints
describe.skip('Given utils helper', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  describe('When the "createFromAction" is called with "action: test-action"', () => {
    it('Then the "checkout.createFromAction" should be called with "action: test-action"', () => {
      const checkoutMock = jest.spyOn(AdyenCheckout.prototype, 'createFromAction').mockImplementation(() => ({ mount: jest.fn() }));

      const actionResponse = {
        action: JSON.stringify({ action: 'test-action' }),
      };

      const actionConfig = {
        environment: 'test',
        clientKey: 'test-key',
      };

      createFromAction(actionConfig, actionResponse);

      expect(checkoutMock).toHaveBeenCalledWith({ action: 'test-action' });
    });
  });

  describe('When the "storeRedirectPaymentMethod" is called with payment method and customer data info', () => {
    it('Then the "authenticateRedirect" should be called with payment method response data', async () => {
      profileDataStore.state.currentProfile.type = 'user';
      const paymentMethodData = {
        reference: 'ref',
        paymentData: 'paymentData',
        paymentMethodUuid: 'paymentMethodUuid',
        type: 'card',
      };

      const customerData = {
        profileType: 'user',
        userUuid: 'userUUID',
      };

      await storeRedirectPaymentMethod(paymentMethodData, customerData);

      expect(storePaymentMethodByUUID).toHaveBeenCalledWith('user', 'paymentMethodUuid');
      expect(authenticateRedirect).toHaveBeenCalledWith(
        {
          reference: 'ref',
          paymentData: 'paymentData',
          userUuid: 'userUUID',
          session: 'testMD',
          paResponse: 'testPaResponse',
          type: 'card',
        },
      );
    });
  });

  describe('When the "onAdditionalDetails" is called', () => {
    it('Then the "checkout.createFromAction" should be called with "action: test-action"', () => {
      const checkoutMock = jest.spyOn(AdyenCheckout.prototype, 'createFromAction').mockImplementation(() => ({ mount: jest.fn() }));

      const actionResponse = {
        action: JSON.stringify({ action: 'test-action' }),
      };

      const actionConfig = {
        environment: 'test',
        clientKey: 'test-key',
      };

      onAdditionalDetails(actionConfig, actionResponse);

      expect(checkoutMock).toHaveBeenCalledWith({ action: 'test-action' });
    });
  });

  describe('When the "handleOnAdditionalDetails" is called with payment method and customer data info', () => {
    it('Then the "authenticateChallenge" should be called with payment method response data', async () => {
      const stateData = {
        data: {
          details: {
            threeds2: {
              challengeResult: 'resultTest',
            },
          },
        },
      };

      const componentData = null;

      profileDataStore.state.currentProfile.type = 'user';

      const responseData = {
        reference: 'ref',
        paymentData: 'paymentData',
        providerStatus: 'providerStatusData',
      };

      const customerData = {
        profileType: 'user',
        userUuid: 'userUUID',
        type: 'user',
      };

      await handleOnAdditionalDetails(stateData, componentData, responseData, customerData, CHALLENGE_ENDPOINT_VERSION.add);

      expect(authenticateChallenge).toHaveBeenCalledWith(
        {
          reference: 'ref',
          paymentData: 'paymentData',
          verificationStep: 'providerStatusData',
          companyUuid: null,

          details: {
            threeds2: {
              challengeResult: 'resultTest',
            },
          },
          generatedToken: 'resultTest',
          type: 'user',
          userUuid: 'userUUID',
        },
        'v3',
      );
    });
  });
});
